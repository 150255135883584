import React, { useImperativeHandle, useRef } from "react"
import { StaticQuery, graphql, Link } from "gatsby";

import * as styles from "./menu.module.scss"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  menuLink(pageLink, title) {
    return (
      <Link to={pageLink}>
        {title}
      </Link>
    )
  }

  menuListItem(key, title, pageLink) {
    if (this.props.page === pageLink) {
      return (
        <li class="body-bold" key={key}>
          {title}
        </li>
      )
    } else {
      return (
        <li class="body-regular" key={key}>
          {this.menuLink(pageLink, title)}
        </li>
      )
    }
  }

  render() {
    return (
      <div
        role="button"
        tabIndex="0"
        className={`${styles.menu} ${this.state.open ? `${styles.open}` : ""}`}
        onClick={() => this.close()}
        onKeyDown={() => this.close()}
      >
        <StaticQuery
          query={query}
          render={data => (
            <>
            <ul>
              {this.menuListItem("about", "About", "/about")}
              <li class="body-regular" key={`contact`}>
                <a href={`mailto:${data.strapiAbout.email}`}>
                  Contact
                </a>
              </li>
            </ul>
             <h2 class="section-title-regular">Stories</h2>
             <ul>
              {data.allStrapiStory.edges.map((story, i) => (
                this.menuListItem(`story__${story.node.slug}`, story.node.title, `/story/${story.node.slug}`)
              ))}
            </ul>
            </>
          )}
        />
      </div>
    )
  }

  close() {
    this.setState({ open: false })
    this.props.onChange(false);
  }

  open() {
    this.setState({ open: true })
    this.props.onChange(true);
  }
}

export default React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
    close() {
      menuRef.current.close()
    },
  }))

  return <Menu ref={menuRef} {...props} />
})

export const query = graphql`{
  strapiAbout {
    email
  }
  allStrapiStory {
    edges {
      node {
        strapiId
        slug
        title
      }
    }
  }
}
`