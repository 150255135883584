/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Seo from "../components/seo"
import Container from "./container"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ seo, page, children }) => {

  return (
    <>
      <Seo seo={seo} />
      <Header page={page}/>
      <Container>
        <main>{children}</main>
        <Footer />
      </Container>
    </>
  );
};

Layout.propTypes = {
  page: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
