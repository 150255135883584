import { Link } from "gatsby"
import React from "react"

import { footer, footerItems } from "./footer.module.css"

const Footer = () => (
  <footer className={footer}>
    <ul className={footerItems}>
      <li>© Luis Laugga</li> 
      <li><Link to="/#">Terms and Conditions</Link></li>
      <li><a href="https://www.instagram.com/luislaugga/">Instagram</a></li>
      <li><a href="https://vsco.co/laugga/">VSCO</a></li>
    </ul>
  </footer>
)

export default Footer
